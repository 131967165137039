import React, { useEffect, useState, useRef } from 'react';

// LIBRARIES
import { useNavigate, useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { useQuery, useMutation } from '@tanstack/react-query';
import { QrReader } from 'react-qr-reader';
import { useSnackbar } from 'notistack';

// MATERIAL
import { LinearProgress, Typography, Button } from '@mui/material';
import { Flex, Image, Heading } from '@aws-amplify/ui-react';

// CONTEXT
// import { useLocationContext } from '../components/LocationContext';

const actionKey = async (postBody) => {
  const result = await API.post('Scanseqr', 'authentication/action-device-key', {
    body: postBody,
  }).catch((err) => {
    throw err;
  });
  // console.log('RESULT: ', result);
  return result || [];
};

export default function ScanProcess(latitude, longitude, deviceKey) {
  const { encodedData } = useParams();

  const [errorMessage, setErrorMessage] = useState();
  const [statusMessage, setStatusMessage] = useState(`Scanning for ${deviceKey?.deviceName ?? 'a '} QR`);
  const [scanning, setScanning] = useState(true);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    if (statusMessage === 'Enter now') {
      const intervalRef = window.setTimeout(() => {
        navigate('/login');
        // window.location.href = 'https://www.scanseqr.com'; // Redirect to ScanseQR.com
      }, 4000);
      return () => {
        window.clearInterval(intervalRef);
      };
    }
  }, [statusMessage, navigate]);

  const mutation = useMutation(actionKey, {
    onSuccess: (data) => {
      if (data.result === 'Granted') {
        setStatusMessage('Enter now');
      }
    },
    onError: (error) => {
      setStatusMessage('ERROR');
    },
  });

  function handleQRdata(result, error) {
    if (!!result) {
      const data = result?.text;

      console.log('QR Data:', data);

      setScanning(false);
      if (data.length < 20) {
        setErrorMessage('Not a valid ScanseQR code');
        enqueueSnackbar('API Error actioning Invite.', { variant: 'error' });
        return;
      }
      const splitData = data.split('/');
      if (!(splitData[2] === 'scanseqr.com' || splitData[2] === 'uat.scanseqr.com' || splitData[2] === 'dev.scanseqr.com')) {
        setErrorMessage('Not a valid ScanseQR code');
        enqueueSnackbar(`${data} is not a ScanseQR code.`, { variant: 'error' });
        return;
      }

      if (!deviceKey) {
        navigate(`/${splitData[3]}/${splitData[4]}`);
        return;
      }

      if (splitData[4] === deviceKey.uuid) {
        setStatusMessage(`Opening ${deviceKey.deviceName}`);
        enqueueSnackbar(`Opening ${deviceKey.deviceName}.`, { variant: 'success' });
        // console.log(userLocation);
        mutation.mutate({
          key: encodedData,
          lat: latitude,
          lng: longitude,
          method: 'Access Key',
        });
      } else {
        setErrorMessage('Key is not for this device');
        enqueueSnackbar('Key is not for this device.', { variant: 'error' });
      }
    }

    if (!!error && JSON.stringify(error) !== JSON.stringify({})) console.log(error); //setQRData('Error reading QR code');
  }

  return (
    <>
        <Typography
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '20px',
          }}
          variant='h5'
        >
          {statusMessage}
        </Typography>

      {scanning && (
        <>
          <LinearProgress />
          <QrReader
            key='environment'
            constraints={{ facingMode: 'environment' }}
            style={{ width: '80%' }}
            onResult={(result, error) => handleQRdata(result, error)}
          />
        </>
      )}

      {errorMessage && (
        <Flex
          style={{
            display: 'grid',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ paddingTop: 10 }}>{errorMessage}</Typography>
          <Button
            sx={{
              backgroundColor: '#FF9900',
              color: '#FFFFFF',
            }}
            onClick={() => {
              setScanning(true);
              setErrorMessage(null);
            }}
          >
            SCAN
          </Button>
        </Flex>
      )}
    </>
  );
}
