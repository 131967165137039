import React, { useEffect, useState } from 'react';

// LIBRARIES
import { useNavigate, useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { useQuery } from '@tanstack/react-query';
import { Flex, Image, Heading } from '@aws-amplify/ui-react';

// MATERIAL
import { Typography } from '@mui/material';

// COMPONENTS
import Scan from './Scan';

// ASSETS
import logo192 from '../assets/logo192.png';

function Header() {
  const headerText = 'Welcome to ScanseQR';
  const [charIndex, setCharIndex] = useState(1);

  useEffect(() => {
    if (charIndex < headerText.length) {
      setTimeout(() => setCharIndex(charIndex + 1), 100);
    }
  }, [charIndex]);

  return (
    <Flex justifyContent='center' alignItems={'center'} direction={'column'}>
      <Image alt='logo' src={logo192} style={{ paddingTop: 20 }} />
      <Heading
        level={3}
        style={{
          marginBottom: 15,
          fontFamily: 'Courier New',
          color: '#ff9903',
        }}
      >
        {headerText.slice(0, charIndex)}
      </Heading>
    </Flex>
  );
}

const validateKey = async (postBody) => {
  const result = await API.post('Scanseqr', 'authentication/validate-device-key', {
    body: postBody,
  }).catch((err) => {
    throw err;
  });
  // console.log('RESULT: ', result);
  return result || [];
};

export default function AccessToken() {
  const { encodedData } = useParams();

  // Queries
  const { data: deviceKey, status: keyValidated } = useQuery({
    queryKey: ['deviceKey'],
    queryFn: () => validateKey({ key: encodedData }),
    // The query will not execute until follow condition is met
    // enabled: !!accessToken
  });

  const [statusMessage, setStatusMessage] = useState('Validating device key');
  const navigate = useNavigate();

  useEffect(() => {
    if (
      keyValidated === 'error' ||
      (keyValidated === 'success' && deviceKey?.success === false)
    ) {
      setStatusMessage('Error validating Access Key');
      const intervalRef = window.setTimeout(() => {
        navigate('/login');
        // window.location.href = 'https://www.scanseqr.com'; // Redirect to ScanseQR.com
      }, 4000);
      return () => {
        window.clearInterval(intervalRef);
      };
    }
    // setStatusMessage(`Scanning for ${deviceKey?.deviceName ? deviceKey.deviceName : 'a '} QR`);
  }, [deviceKey, keyValidated, navigate]);

  return (
    <>
      {deviceKey && Header()}
      <Typography
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '20px',
        }}
        variant='h5'
      >
        {statusMessage}
      </Typography>

      {keyValidated === 'success' &&
        deviceKey?.success === true && ( // deviceKey?.online === true
          <Scan deviceKey={deviceKey} />
        )}

      {keyValidated === 'success' &&
        deviceKey.success === true &&
        deviceKey.online !== true && (
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '20px',
            }}
            variant='h5'
          >
            Device might be Offline
          </Typography>
        )}
    </>
  );
}
