import { useEffect, useState } from 'react';
// LIBRARIES
import { API, Auth } from 'aws-amplify';
// import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Authenticator, Flex, ThemeProvider, useAuthenticator } from '@aws-amplify/ui-react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
// import { useSnackbar } from 'notistack';
// ASSETS
import qrCodeBackground from '../assets/qrCode.png';
// THEME
import amplifyTheme from '../styles/amplifyTheme';
// FUNCTIONS
// import { applyInviteApi, checkInviteApi } from '../functions/invite';
// COMPONENTS
import { AuthComponents, LoginFormFields } from '../components/AuthenticatorComponents';
// import { LoadingOverlay } from '../components/LoadingOverlay';

import amplifyExports from '../aws-exports';


export default function Login(props) {
  const providers = amplifyExports.Auth.aws_cognito_social_providers.map(
    (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase()
  );
  const { initialState, invite } = props;
  // const [checkingInvite, setCheckingInvite] = useState(false);
  // const { encodedData } = useParams();
//   const { id, encodedData } = useParams();


  // const { enqueueSnackbar } = useSnackbar();
  // const queryClient = useQueryClient();

  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();

    // If the user specifies a from path, use that instead of the default when logging in
  let from = location.state?.from?.pathname || '/';
  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

    // Mutations
  //   const inviteMutation = useMutation(applyInviteApi, {
  //     onSuccess: async () => {
  //       console.log('L INVITE MUTATION SUCCESS');
  //         await Auth.currentAuthenticatedUser({ bypassCache: true });
  //         // queryClient.removeQueries(['invites']);
  //         queryClient.setQueriesData(['invites'], { isActioned: true });
  //         queryClient.invalidateQueries(['devices']);
  //         enqueueSnackbar('Invitation accepted.', { variant: 'success' });
  //     },
  //     onError: (err) => {
  //       console.log('I INVITE MUTATION ERROR');
  //       // enqueueSnackbar('API Error actioning Invite.', { variant: 'error' });
  //     }
  // });

  // Queries
  // const { data: inviteData, status: inviteStatus } = useQuery({
  //   queryKey: ['invites'],
  //   queryFn: () => checkInviteApi(invite),
  //   enabled: !!invite,
  // });

//   useEffect(() => {
//     if(!!invite && inviteData && inviteStatus === 'success') {
//       console.log('Login Invite', inviteData);
//       console.log(inviteData, inviteStatus);
//       if(inviteData.isActioned === true) {
//         console.log('I ALREADY ACTIONED');
//         return;
//       }
//       if(inviteData.name === "Invite mismatch") {
//         console.log('D INVITE MISMATCH');
//         enqueueSnackbar('Your invite does not match your account.', { variant: 'error' });
//         navigate('/login', { replace: true });
//         return;
//       }
//       if (inviteData.isExpired === true) {
//           console.log('I INVITE EXPIRED');
//           enqueueSnackbar('Your invite url is expired.', { variant: 'error' });
//           return;
//       } 
//       console.log('I INVITE GOOD');
//       enqueueSnackbar(`${inviteData.name}, You have been invited to ${inviteData.entityName}.`, {
//           variant: 'success',
//       });
//       enqueueSnackbar('Please Sign In or Sign Up to activate Invite.', { variant: 'info' });
//       console.log('Create Account');
      
//   }
// }, [inviteData, inviteStatus, enqueueSnackbar]);

  const services = {
    async handleSignIn(formData) {
      return await Auth.signIn(formData).then(async (user) => {
        // if (invite) {
        //   console.log('I INVITE APPLY SERVICE');
        //     const postBody = {
        //       body: { invite: invite },
        //     };
        //     inviteMutation.mutate(postBody);
        //   }
        return user;
      });
    },
  };

  return (
    <ThemeProvider theme={amplifyTheme}>
      {/* <LoadingOverlay visible={checkingInvite} /> */}
      {/* {data && (
        <>
          <Typography variant='h3' sx={{ flexGrow: 1, textAlign: 'center', pt: '20px' }}>
            {data.device_name}
          </Typography>
          <Typography variant='h6' sx={{ flexGrow: 1, textAlign: 'center'}}>{data.entity_name}</Typography>
          <Typography variant='subtitle2' sx={{ flexGrow: 1, textAlign: 'center', marginBottom: '-50px' }}>
            {data.website}
          </Typography>
        </>
      )} */}
      <Flex
        justifyContent='center'
        style={{
          backgroundImage: `url(${qrCodeBackground})`,
          backgroundRepeat: 'repeat',
          backgroundColor: 'transparent',
          height: '100vh',
        }}
      >
        <Authenticator
          initialState={initialState}
          formFields={LoginFormFields}
          services={services}
          loginMechanisms={['email']}
          // Removed as is does not provide way to parse state
          // socialProviders={['google']}
          signUpAttributes={['phone_number', 'email']}
          components={AuthComponents(invite, providers)}
        />
      </Flex>
    </ThemeProvider>
  );
}
